//
// Generic
// Settings - Tools - GENERIC - Elements - Objects - Components - Vendors - Utilities
//

@import 'root';
@import 'bootstrap';

.ant-tabs-tab {
	border-radius: 50px;
	color: #666 !important;
	font-weight: 700 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
	border-bottom: 2px solid #1890ff !important;
	background: #30313703 !important;
	z-index: 2;
}

.ant-pagination {
	border-radius: 4px;
	color: #666;
	font-weight: 700;
}

.ant-pagination-item-active {
	background-color: #1890ff;
	border-color: #1890ff;
}

.ant-pagination-item-active a {
	color: #171616;
}

.ant-pagination-item:hover {
	border-color: #40a9ff;
}

.ant-pagination-item:hover a {
	color: #40a9ff;
}

.ant-pagination-prev,
.ant-pagination-next {
	color: #666;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	color: #666;
	font-weight: 700;
}

.ant-pagination-prev .ant-pagination-item-link svg,
.ant-pagination-next .ant-pagination-item-link svg {
	fill: #666;
	font-weight: 700;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: #40a9ff;
}

.ant-pagination-prev:hover .ant-pagination-item-link svg,
.ant-pagination-next:hover .ant-pagination-item-link svg {
	fill: #40a9ff;
}

.ant-radio-wrapper .ant-radio + span {
	color: #666;
	font-weight: 600;
}
